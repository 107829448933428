<template>
  <div :style="containerStyle" class="tw-video">
    <iframe
      v-if="prepareEmbedUrl"
      title="Video"
      :src="srcUrl"
      :style="style"
    />
    <div
      v-else
      class="tw-broken-image"
      :style="style"
    >
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="80"
          height="80"
          rx="8"
          fill="#151B26"
          fill-opacity="0.5"
        />
        <g clip-path="url(#clip0_21433_38453)">
          <path d="M51.4212 20.1797C50.1612 20.1797 48.9212 20.4197 47.7612 20.8997C46.6012 21.3797 45.5412 22.0797 44.6612 22.9797L37.2013 30.4397C37.7613 30.3597 38.3412 30.2797 38.9212 30.2797C40.5012 30.2797 42.0813 30.5797 43.5413 31.1797L48.1812 26.5397C49.0412 25.7397 50.2012 25.2997 51.3812 25.3197C52.5612 25.3397 53.6812 25.8197 54.5212 26.6597C55.3612 27.4997 55.8413 28.6197 55.8613 29.7997C55.8813 30.9797 55.4412 32.1197 54.6413 32.9997L46.2812 41.3597C44.5612 43.0797 41.5412 43.0797 39.8212 41.3597L36.2812 44.8997C37.1612 45.7997 38.2212 46.4997 39.3812 46.9797C40.5412 47.4597 41.7812 47.6997 43.0413 47.6997C44.3013 47.6997 45.5413 47.4597 46.7013 46.9797C47.8612 46.4997 48.9212 45.7997 49.8012 44.8997L58.1612 36.5397C59.0612 35.6597 59.7612 34.5997 60.2412 33.4397C60.7212 32.2797 60.9613 31.0397 60.9613 29.7797C60.9613 27.2397 59.9412 24.7997 58.1612 23.0197C56.3612 21.2197 53.9412 20.2197 51.4012 20.2197L51.4212 20.1797Z" fill="#ACB4C3" />
          <path d="M30.38 61.005C27.62 61.005 25 59.925 23.06 57.965C21.08 56.005 20 53.385 20 50.625C20 49.265 20.26 47.925 20.78 46.665C21.3 45.405 22.06 44.265 23.04 43.305L31.4 34.925C35.3 31.025 42.16 31.025 46.06 34.925L46.62 35.485L41.96 40.165L41.4 39.605C39.98 38.185 37.48 38.185 36.06 39.605L27.7 47.965C27.06 48.665 26.7 49.605 26.72 50.585C26.72 51.565 27.14 52.485 27.82 53.185C28.52 53.885 29.44 54.265 30.42 54.285C31.4 54.365 32.34 53.945 33.06 53.285L38.02 48.325L38.52 48.525C40.3 49.265 42.26 49.545 44.14 49.285L46.74 48.945L37.72 57.965C36.76 58.925 35.62 59.685 34.36 60.225C33.1 60.745 31.76 61.005 30.4 61.005H30.38ZM38.74 33.585C36.46 33.585 34.18 34.405 32.54 36.065L24.18 44.425C23.36 45.245 22.72 46.205 22.28 47.265C21.84 48.325 21.62 49.465 21.62 50.625C21.62 52.965 22.54 55.165 24.2 56.825C25.86 58.485 28.06 59.405 30.4 59.405C31.56 59.405 32.68 59.185 33.74 58.745C34.8 58.305 35.76 57.665 36.58 56.845L42.42 51.005C41.04 50.965 39.7 50.685 38.4 50.205L34.18 54.425C33.14 55.385 31.74 55.865 30.4 55.865C29 55.845 27.7 55.285 26.7 54.285C25.72 53.305 25.16 51.985 25.12 50.585C25.1 49.185 25.6 47.845 26.54 46.825L34.92 38.465C36.8 36.605 39.82 36.425 41.9 37.945L44.32 35.525C42.74 34.225 40.72 33.585 38.72 33.585H38.74Z" fill="#ACB4C3" />
          <path d="M29.0995 28.6596C29.2395 28.7396 29.3995 28.7796 29.5595 28.7796C29.8595 28.7796 30.1395 28.6196 30.2995 28.3596C30.5595 27.9396 30.4195 27.3996 29.9995 27.1596L26.8195 25.2396C26.3995 24.9796 25.8595 25.1196 25.6195 25.5396C25.3595 25.9596 25.4995 26.4996 25.9195 26.7396L29.0995 28.6596Z" fill="#ACB4C3" />
          <path d="M37.7016 25.5601C38.0616 25.5601 38.4016 25.3401 38.5216 24.9801L39.7616 21.4801C39.9216 21.0201 39.6816 20.5201 39.2216 20.3601C38.7616 20.2001 38.2616 20.4401 38.1016 20.9001L36.8816 24.4001C36.7216 24.8601 36.9616 25.3601 37.4216 25.5201C37.5216 25.5601 37.6216 25.5601 37.7216 25.5601H37.7016Z" fill="#ACB4C3" />
          <path d="M32.9399 25.2597C33.0399 25.2597 33.1599 25.2597 33.2599 25.1997C33.6999 25.0197 33.9399 24.5197 33.7599 24.0597L32.3799 20.5397C32.1999 20.0997 31.6999 19.8597 31.2399 20.0397C30.7999 20.2197 30.5599 20.7197 30.7399 21.1797L32.1199 24.6997C32.2599 25.0397 32.5799 25.2597 32.9399 25.2597Z" fill="#ACB4C3" />
          <path d="M50.7398 48.3203C50.5798 48.3203 50.4198 48.3403 50.2798 48.4203C50.0198 48.5603 49.8198 48.8203 49.7998 49.1403C49.7598 49.6203 50.1398 50.0403 50.6198 50.0803L54.3198 50.3403C54.7998 50.3803 55.2198 50.0003 55.2598 49.5203C55.2998 49.0403 54.9198 48.6203 54.4398 48.5803L50.7398 48.3203Z" fill="#ACB4C3" />
          <path d="M44.5019 55.0204C44.1819 55.1804 43.9819 55.5404 44.0419 55.9204L44.5419 59.6004C44.6019 60.0804 45.0419 60.4204 45.5219 60.3404C46.0019 60.2804 46.3419 59.8404 46.2619 59.3604L45.7619 55.6804C45.7019 55.2004 45.2619 54.8604 44.7819 54.9404C44.6819 54.9404 44.5819 54.9804 44.5019 55.0204Z" fill="#ACB4C3" />
          <path d="M48.8598 53.0999C48.8598 53.0999 48.6798 53.2199 48.5998 53.2999C48.2798 53.6599 48.3198 54.2199 48.6798 54.5399L51.5198 57.0399C51.8798 57.3599 52.4398 57.3199 52.7598 56.9599C53.0798 56.5999 53.0398 56.0399 52.6798 55.7199L49.8398 53.2199C49.5598 52.9799 49.1798 52.9399 48.8598 53.0999Z" fill="#ACB4C3" />
        </g>
        <defs>
          <clipPath id="clip0_21433_38453">
            <rect
              width="41"
              height="41.18"
              fill="white"
              transform="translate(20 20)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { type Element as ElementType } from '@shared/types/model';
import { type PropType, computed } from 'vue';
import { getAssetFromCdn, youtubePattern, vimeoPattern } from '@shared/utils/helpers';

const props = defineProps({
  tagName: { type: String, default: 'img' },
  value: { type: String, default: '' },
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'image'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'image'>>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false }
});

const embedType = computed(() => {
  const videoType = props.element.options.mediaType;
  return videoType?.replace(/^embed_/, '');
})

const prepareEmbedUrl = computed(() => {
  const url = props.element.options.videoEmbedURL;

  if (embedType.value === 'youtube') {
    const match = url?.match(youtubePattern);  
    if (match && match[5]) {
      return `https://www.youtube.com/embed/${match[5]}`;
    }
  } else if (embedType.value === 'vimeo') {
    const match = url?.match(vimeoPattern);  
    if (match && match[5]) {
      return `https://player.vimeo.com/video/${match[5]}`;
    }
  }

  return false;
});

const srcUrl = computed(() => {
  const url = prepareEmbedUrl?.value;
  if (!url) return '';

  const [baseUrl, existingQuery] = url.split('?');
  const urlParams = new URLSearchParams(existingQuery || '');

  urlParams.set('rel', '0')

  if (url && props.element.options.loop) {
    const videoId = getYoutubeVideoId(url);
    urlParams.set('loop', '1');
    if (videoId) {
      urlParams.set('playlist', videoId);
    }
  }

  if (props.element.options.autoplay) {
    urlParams.set('autoplay', '1');
    if (url.includes('vimeo.com')) {
      urlParams.set('muted', '1');
    } else {
      urlParams.set('mute', '1');
    }
    urlParams.set('controls', '0');
    if (props.isEditorMode) {
      urlParams.set('autoplay', '0');
    }
  } else {
    urlParams.set('controls', '1');
  }

  const queryString = urlParams.toString();
  const resultUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;

  return resultUrl;
});

const style = computed(() => {
  const backgroundImage = `url(${getAssetFromCdn('editor/noVideo.png')})`;
  const defaultStyle = {
    ...props.style,
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    backgroundImage,
    backgroundColor: 'black',
  } as any;
  if (props.element.options.videoFullSize) {
    return {
      ...defaultStyle,
      position: 'absolute',
      top: '0',
      left: '0',
    };
  } else {
    return defaultStyle;
  }
});

const containerStyle = computed(() => {
  if (props.element.options.videoFullSize) {
    return {
      ...props.style,
      'padding-bottom': '56.25%',
      height: '0',
      width: '100%',
    };
  } else {
    return {
      ...props.style,
      maxWidth: '100%',
      width: `${props.element.options.videoWidth}px`,
      height: `${props.element.options.videoHeight}px`
    };
  }
});

function getYoutubeVideoId(url: string) {
  const youtubeRegex =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube.com\/embed\/)([^"&?/\s]{11})/i;
  const match = url.match(youtubeRegex);

  if (match && match[1]) {
    return match[1]; // Returns the video ID
  } else {
    return null; // Not a YouTube URL or ID not found
  }
}
</script>

<style lang="postcss" scoped>
.tw-broken-image {
  @apply bg-no-repeat bg-center bg-cover flex items-center justify-center;
}
</style>
